import { Autocomplete, Box, Button, Grid, TextField, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getBranches, getUsersData, getDealsFromClient, getGrowersFromClient, getJobs } from "../api/airtable_ops";
import { ATRecord, Branches, Companies, Deals, Growers, getField } from "@rogoag/airtable";
import { JobCreationData } from "../types";
import { UserContext } from "../hooks/UserContext";
import 'react-csv-importer/dist/index.css';
import { ManualEntryForm } from "./ManualEntryForm";

import { PortalConfig } from '../types';

import { SHP } from "../geojson_converters/shp";
import { CSVImportForm } from "./CSVImportForm";
import { ProagricaImportForm } from "./ProagricaImportForm";
import DealSelectionAutocomplete from "./DealSelectionAutocomplete";
import AirtableRecordSelectionAutocomplete from "./AirtableRecordSelectionAutocomplete";
import { LoadingComponent } from "./LoadingComponent";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

const UploadTypes = [
    "Boundaries",
    "Points",
    "Zones",
    "Boundaries and Points",
    "Boundaries and Zones",
    "Boundaries and Zones and Cores",
] as const;


/*                 
Actions              
┌────────────────────────┐                                       ┌────────────────────────┐       ┌────────────────────────┐
│    Add Shapefiles      │                                       │    Set Unique Key      │       │   Set Category Keys    │
└────────────────────────┘                                       └────────────────────────┘       └────────────────────────┘
             │                                                               │                                 │             
States       ▼                                                               ▼                                 ▼             
┌────────────────────────┐    ┌──────────────────────────┐    ┌───────────────────────------─┐    ┌────────────────────────┐    ┌────────────────────────┐
│ Original Uploads       │    │ Keyed Shapefiles         │    │ Unique Features              │    │  Grouped Features      │    │  Final Shapefiles      │
│                        │    │                          │    │ - feature: FeatureCollection │    │                        │    │                        │
│ - files: File[]        │───►│ - file: File             │───►│ - keys: string[]             │───►│                        │───►│                        │
│                        │    │ - key: string            │    │                              │    │                        │    │                        │
│                        │    │ - dbfKeys: string[]      │    │                              │    │                        │    │                        │
│                        │    │ - path: string           │    │                              │    │                        │    │                        │
└────────────────────────┘    └──────────────────────────┘    └───────────────────────------─┘    └────────────────────────┘    └────────────────────────┘
                                                                             │                                                                ▲
                                                                             └----------------------------------------------------------------┘

                                 
*/

interface UniqueKeyedCategorizedFeature {
    feature: GeoJSON.FeatureCollection;
    type: "Unknown" | "Boundary" | "Points" | "Zones";
    key: string;
}

interface JobSubmissionFormProps {
    initialState?: JobCreationData;
}

export default function JobSubmissionForm(props: JobSubmissionFormProps) {
    const userContext = useContext(UserContext);

    const [deals, setDeals] = useState<ATRecord<Deals>[]>([]);
    const [selectedDeals, setSelectedDeals] = useState<ATRecord<Deals>[]>([]);
    const [growers, setGrowers] = useState<ATRecord<Growers>[]>([]);
    const [selectedGrowers, setSelectedGrowers] = useState<ATRecord<Growers>[]>([]);
    const [companyOptions, setCompanyOptions] = useState<ATRecord<Companies>[]>([]);
    const [branches, setBranches] = useState<ATRecord<Branches>[]>([]);
    const [selectedBranches, setSelectedBranches] = useState<ATRecord<Branches>[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<ATRecord<Companies> | null>(null);
    // const [growerOptions, setGrowerOptions] = useState<{ label: string, id: string }[]>([]);
    const [loading, setLoading] = useState(false);
    const [isZone, setIsZone] = useState(false);
    const [importOptions, setImportOptions] = useState<PortalConfig[]>([]);
    const [agvanceLoginOpen, setAgvanceLoginOpen] = useState(false);
    const [agvanceUsername, setAgvanceUsername] = useState("");
    const [agvancePassword, setAgvancePassword] = useState("");
    const [dataImportOption, setDataImportOption] = useState<PortalConfig | null>(null);
    const [jobData, setJobData] = useState<JobCreationData[]>([]);

    const [shapefileFields, setShapefileFields] = useState<string[]>([]);
    const [shapefileFieldsToParse, setShapefileFieldsToParse] = useState<string[]>([]);

    // step 1: User adds shapefiles
    const [originalShapefiles, setOriginalShapefiles] = useState<File[]>([]);

    // step 2: Transform originalShapefiles into KeyedShapefiles

    // step 3: Transform keyed shapefiles into unique shapefiles
    const [uniqueKeyedFeatures, setUniqueKeyedFeatures] = useState<Map<string, UniqueKeyedCategorizedFeature[]>>(new Map());

    // step 4: Transform unique shapefiles into categorized shapefiles
    //const [uniqueKeyedCategorizedFeatures, setUniqueKeyedCategorizedFeatures] = useState<UniqueKeyedCategorizedFeature[]>([]);

    // step 5: Final shapefiles
    const [transformedShapefiles, setTransformedShapefiles] = useState<File[]>([]);

    const [selectableFiles, setSelectableFiles] = useState<File[]>([]);

    const [boundaryFiles, setBoundaryFiles] = useState<File[]>([]);
    const [pointsFiles, setPointsFiles] = useState<File[]>([]);
    const [zoneFiles, setZoneFiles] = useState<File[]>([]);

    const [selectedBoundaryFiles, setSelectedBoundaryFiles] = useState<File[]>([]);
    const [selectedPointsFiles, setSelectedPointsFiles] = useState<File[]>([]);
    const [selectedZoneFiles, setSelectedZoneFiles] = useState<File[]>([]);

    const [matchedPointsFiles, setMatchedPointsFiles] = useState<File[]>([]);

    const [useFilenameOnly, setUseFilenameOnly] = useState(false);

    // const [selectedUploadType, setSelectedUploadType] = useState<UploadType | null>(null);

    // console.log(JSON.stringify(watch()));

    // useEffect(() => {
    //     // console.log(`Growers changed ${growers}`);
    //     const newGrowerOptions = [
    //         { label: CREATE_NEW_GROWER, id: CREATE_NEW_GROWER },
    //         ...growers.map(grower => ({ label: getField(grower, "Grower Name"), id: grower.id }))
    //     ];
    //     setGrowerOptions(newGrowerOptions);
    // }, [growers]);

    useEffect(() => {
        for (const deal of deals) {
            if (selectedDeals.includes(deal)) {
                console.log(deal);
                const jobType = getField(deal, "Sites Type #serviceinfo");
                if (jobType === "Zone") {
                    setIsZone(true);
                } else {
                    setIsZone(false);
                }
                break;
            }
        }
    }, []);

    useEffect(() => {
        const getShapeData = async () => {
            if (!originalShapefiles.length) {
                return;
            }

            const buffer = Buffer.from(await originalShapefiles[0].arrayBuffer())
            const geoJSON = await SHP.toGeoJSON(buffer);
            const properties = geoJSON.features[0].properties;
            if (!properties) {
                setShapefileFields([]);
                return;
            }
            const propertyKeys = Object.keys(properties);
            const folderTokens = originalShapefiles[0].name.split('/');
            const folderOptions: string[] = [];
            for (let folderLevel = 1; folderLevel <= folderTokens.length; folderLevel++) {
                folderOptions.push("Folder Level " + folderLevel);
            }
            setShapefileFields(() => [...folderOptions, ...propertyKeys]);
        }

        getShapeData();
    }, [originalShapefiles]);

    useEffect(() => {
        // React advises to declare the async function directly inside useEffect
        async function getData() {
            try {
                const [userRecord, companyRecords, deals, importOptions, userCompany] = await getUsersData(userContext.id);
                setImportOptions(importOptions);
                if (importOptions.length === 1) {
                    setDataImportOption(importOptions[0]);
                }
                // sort in place by company name
                companyRecords.sort((companyA, companyB) => getField(companyA, "Name").localeCompare(getField(companyB, "Name")));
                setCompanyOptions(companyRecords);
                if (companyRecords.length === 1) {
                    const companyRecord = companyRecords[0];
                    setSelectedCompany(companyRecord);
                    const [growers, branches] = await Promise.all([
                        await getGrowersFromClient(companyRecord),
                        await getBranches(companyRecord)
                    ]);
                    sortDeals(deals);
                    setDeals(deals);
                    setGrowers(growers);
                    setBranches(branches);
                }
                setLoading(false);
            } catch (error) {
                toast.error("Error loading data");
                Sentry.captureException(error);
            }

        };

        // You need to restrict it at some point
        // This is just dummy code and should be replaced by actual
        if (!deals.length) {
            setLoading(true);
            getData();
        }
    }, []);

    async function onCompanyChanged(value: ATRecord<Companies> | null) {
        setLoading(true);
        if (value) {
            // Order may be important if things are asynchronously rerendering...
            const branches = await getBranches(value);
            setSelectedBranches([]);
            setBranches(branches);

            const growers = await getGrowersFromClient(value);
            setSelectedGrowers([]);
            setGrowers(growers);

            const deals = await getDealsFromClient(value);
            setSelectedDeals([]);
            setDeals(deals);
        }
        setSelectedCompany(value);
        setLoading(false);
    }

    function sortDeals(deals: ATRecord<Deals>[]) {
        deals.sort((dealA, dealB) => {
            const densityA = getField(dealA, "Density/Grid #serviceinfo");
            const densityB = getField(dealB, "Density/Grid #serviceinfo");
            return densityA - densityB;
        });
    }


    function DataImportSelection() {
        // retrun a grid with four buttons that represent DataImportOptions
        return <Grid item width={'100%'}>
            <Grid container spacing={1} justifyContent="center">
                {importOptions.map((option, index) => {
                    return <Grid item key={index}>
                        <Button variant={dataImportOption === option ? "contained" : "outlined"}
                            onClick={() => {
                                setDataImportOption(option);
                            }}
                        >{option}</Button>
                    </Grid>
                })}
            </Grid>
        </Grid>;
    }

    async function investigateShapefiles(files: File[], properties: string[], filenamesOnly: boolean = false) {
        console.log('investigateShapefiles', properties);
        // iterate through the map and get all collections of SHP files
        const results: Record<string, GeoJSON.FeatureCollection> = {};
        await Promise.all(files.map(async file => {
            const buffer = Buffer.from(await file.arrayBuffer());
            await SHP.toGeoJSON(buffer).then((geojson) => {
                // count all features grouped by feature type
                const featureCounts: Record<string, number> = {};
                geojson.features.forEach(feature => {
                    if (!featureCounts[feature.geometry.type]) {
                        featureCounts[feature.geometry.type] = 0;
                    }
                    featureCounts[feature.geometry.type]++;
                })

                // if all points, then is either points or zone cores
                // if all polygons, then this is either a boundary or a zone

                console.log(featureCounts);

                if (properties.length === 0 && filenamesOnly) {
                    const tokens = file.name.split('/');
                    const filename = tokens[tokens.length - 1];
                    if (!results[filename]) {
                        results[filename] = {
                            type: "FeatureCollection",
                            features: []
                        };
                    }
                    results[filename].features.push(...geojson.features);
                } else {
                    geojson.features.map(feature => {
                        console.log(feature);
                        const filenameTokens = properties.map(property => {
                            if (property.startsWith("Folder Level")) {
                                const folderLevel = parseInt(property.split(' ')[2]);
                                const tokens = file.name.split('/');
                                const token = tokens[folderLevel - 1];
                                if (token.endsWith('.zip')) {
                                    return token.substring(0, token.length - 4);
                                }
                                return token;
                            }
                            // @ts-ignore TODO check if the property exists first?
                            return feature.properties[property];
                        });
                        let filename = `${filenameTokens.join('_')}.zip`;
                        // const farm = feature.properties.farm;
                        // const field = feature.properties.name;
                        // const grower = feature.properties.grower;
                        // const zipName = `${grower}_${farm}_${field}.zip`;
                        if (!results[filename]) {
                            results[filename] = {
                                type: "FeatureCollection",
                                features: []
                            };
                        }
                        results[filename].features.push(feature);
                    })
                }
            });
        }));

        const boundaryFiles = await Promise.all(Object.keys(results).map(async (zipName) => {
            const featureCollection = results[zipName];
            return await SHP.fromGeoJSON(featureCollection).then(async (zipBuffer) => {
                return new File([zipBuffer], `${zipName}`);
            });
        }));
        setTransformedShapefiles(boundaryFiles);
    }

    function DataImportView() {
        const dealRecord = selectedDeals[0];
        switch (dataImportOption) {
            // case "AgVance":
            //     return <Box>
            //         <Button onClick={() => setAgvanceLoginOpen(true)}>Import From AgVance</Button>
            //     </Box>
            // case "ProAgrica/Agx Import":
            //     return <Box>
            //         <Button>Import From ProAgrica/Agx</Button>
            //     </Box>
            case "CSV + SHP Upload":
                return <CSVImportForm
                    selectedDeals={selectedDeals}
                    selectedBranches={selectedBranches}
                    selectedCompany={selectedCompany}
                    setSelectableFiles={(groupKey: string, files: File[]) => {
                        setSelectableFiles((prev) => {
                            const newFiles = prev.concat(files);
                            return newFiles;
                        });
                    }}
                    deals={deals}
                />;
            case "Single Job Upload":
                return <ManualEntryForm
                    isZone={isZone}
                    growers={growers}
                    selectedDeal={selectedDeals[0]}
                    allDeals={deals}
                    companyRecordId={selectedCompany?.id || ''}
                    deal={dealRecord.id}
                    depth={getField(dealRecord, "Depth (in.) #serviceinfo")}
                    gridSize={getField(dealRecord, "Density/Grid #serviceinfo")}
                    labPackage={getField(dealRecord, 'Test Pckg Display') as string || 'No test package specified'}
                    frequencyOfMicros={getField(dealRecord, 'Freq of Add-Ons #Every Nth Sample  #bill')}
                    company={selectedCompany}
                />;
            case "ProAgrica/Agx Import":
                return <ProagricaImportForm
                    selectedBranches={selectedBranches}
                    selectedDeals={selectedDeals} />;
        }
    }

    return (
        <>
            <Grid
                alignItems="stretch"
                alignContent="stretch"
                container
                maxWidth="md"
                margin="auto"
                spacing={1}
            >
                {/* Add spacing component here */}
                <Grid item width={'100%'} height='20px'></Grid>

                {/* Company Selection */}
                {companyOptions.length > 1 &&
                    <AirtableRecordSelectionAutocomplete
                        key="Name"
                        loading={loading}
                        options={companyOptions}
                        selectedOption={selectedCompany}
                        onChange={onCompanyChanged}
                        label="Choose your company"
                        tooltip="Select a company to submit jobs for. This is only for ROGO employees"
                    />
                }
                {loading && <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100vh">
                    <LoadingComponent />
                </Box>}

                { /* Branch Selection */}
                {(selectedCompany && (branches.length >= 1)) &&
                    <AirtableRecordSelectionAutocomplete
                        key="Branch Name"
                        loading={loading}
                        options={branches}
                        selectedOption={selectedBranches.length ? selectedBranches[0] : null}
                        onChange={(value) => {
                            setSelectedDeals([]);
                            setSelectedBranches(value ? [value] : [])
                        }}
                        optionFormatter={(option) => getField(option, "Branch Name")}
                        label="Choose your branch"
                    />
                    // <BranchSelectionOptions />
                }

                { /* Select Sampling Configuration */}
                {selectedCompany && (branches.length === 0 || selectedBranches.length >= 1) &&
                    <DealSelectionAutocomplete
                        deals={deals}
                        selectedDeals={selectedDeals}
                        loading={loading}
                        setSelectedDeals={setSelectedDeals} />
                }

                { /* Chooose Data Import Method */}
                {(selectedCompany && (branches.length === 0 || selectedBranches.length >= 1) && selectedDeals.length >= 1 && importOptions.length > 1) && <>
                    <DataImportSelection />
                </>}
            </Grid>
            {(selectedCompany && (branches.length === 0 || selectedBranches.length >= 1) && selectedDeals.length >= 1 && dataImportOption) && <>
                <DataImportView />
            </>}
        </>

    );
}
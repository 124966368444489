import { useContext, useEffect, useState } from "react";
import { Controller, set, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { UserContext } from "../hooks/UserContext";
import { JobCreationData, JobsPriorityOvrd } from "../types";
import { TextField, Checkbox, Select, MenuItem, Button, Box, Typography, Grid, Accordion, AccordionDetails, Autocomplete, Tooltip, createFilterOptions } from "@mui/material";
import AccordianSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormField from "./FormField";
import { submitJobs } from "../api/rogo_ops";
import { ATRecord, Companies, Deals, getField, getFieldId, Growers, update } from "@rogoag/airtable";
import { CREATE_NEW_GROWER } from "../constants";
import { toast } from "react-toastify";
import { updateRecord } from "../api/airtable_ops";
import PortalTooltip from "./PortalTooltip";
import { LoadingComponent } from "./LoadingComponent";



const CreateNewGrower = { id: CREATE_NEW_GROWER, [getFieldId("Growers", "Grower Name")]: CREATE_NEW_GROWER, "Grower Name": CREATE_NEW_GROWER } as ATRecord<Growers>;

interface ManualEntryFormProps extends Partial<JobCreationData> {
    isZone: boolean;
    growers: ATRecord<Growers>[];
    // growerOptions: { label: string, id: string }[];
    selectedDeal: ATRecord<Deals>;
    allDeals: ATRecord<Deals>[];
    company: ATRecord<Companies> | null;
}


const filter = createFilterOptions<ATRecord<Growers> | { id: string, "Grower Name": string }>();

export function ManualEntryForm(props: ManualEntryFormProps) {
    const userContext = useContext(UserContext);
    const [growerSelected, setGrowerSelected] = useState<ATRecord<Growers> | { id: string, "Grower Name": string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [deal, setDeal] = useState<ATRecord<Deals> | { id: 'custom', label: 'Create New Configuration' }>(props.selectedDeal);
    const [jobType, setJobType] = useState('');

    const { register, handleSubmit, watch, control, reset, setValue, trigger, formState: { errors } } = useForm<JobCreationData>({
        defaultValues: {
            ...props,
            // growerRecordId CREATE_NEW_GROWER,
            growerRecord: CreateNewGrower,
            fieldPriority: 'Regular Turn (default)',
            submitterEmail: userContext.email,
            submitterName: userContext.full_name,
        }
    });

    useEffect(() => {
        updateDeal();
    }, [watch("frequencyOfMicros"), watch("gridSize"), watch("depth"), watch("labPackage"), watch("deal"), deal]);

    useEffect(() => {
        if (watch("growerRecord")?.id === CREATE_NEW_GROWER) {
            setValue("growerRecordId", CREATE_NEW_GROWER);
        } else {
            setValue("growerRecordId", watch("growerRecord")?.id);
        }
    }, [watch("growerRecord")]);

    const updateDeal = (() => {
        const deal = props.allDeals.find((deal: Deals) => {
            const dealDensity = getField(deal as ATRecord<Partial<Deals>>, 'Density/Grid #serviceinfo');
            const dealDepth = getField(deal as ATRecord<Partial<Deals>>, 'Depth (in.) #serviceinfo');
            const dealLabPackage = getField(deal as ATRecord<Partial<Deals>>, 'Test Pckg Display');
            const dealMicros = getField(deal as ATRecord<Partial<Deals>>, 'Freq of Add-Ons #Every Nth Sample  #bill') || 0;

            const micros = watch("frequencyOfMicros");
            const density = watch("gridSize");
            const depth = watch("depth");
            const labPackage = watch("labPackage");
            return (
                dealDensity == density &&
                dealDepth == depth &&
                dealMicros == micros &&
                (dealLabPackage == labPackage ||
                    ((labPackage == '' || labPackage == 'No test package specified') && (dealLabPackage == '' || dealLabPackage == undefined))
                )
            )
        });
        if (deal) {
            setDeal(deal);
            setValue("deal", deal.id);
            const jobType = getField(deal, "Sites Type #serviceinfo");
            jobType == undefined ? setJobType('Grid') : setJobType(jobType);
        } else {
            setDeal({ id: 'custom', label: 'Create New Configuration' });
            setValue("deal", 'custom');
            setJobType('');
        }
    })

    const getDealLabel = (option: ATRecord<Deals> | { id: string, label: string }) => {
        if (option && option.id === 'custom') {
            return 'Create New Configuration';
        }

        const density = getField(option as ATRecord<Partial<Deals>>, 'Density/Grid #serviceinfo');
        const depth = getField(option as ATRecord<Partial<Deals>>, 'Depth (in.) #serviceinfo');
        const dealType = getField(option as ATRecord<Partial<Deals>>, "Sites Type #serviceinfo")
        const testPackage = getField(option as ATRecord<Partial<Deals>>, 'Test Pckg Display') || 'No test package specified';
        const dealLabelFields = [density, dealType, depth, testPackage];
        const specialTag = getField(option as ATRecord<Partial<Deals>>, 'Sampling Type Special');
        if (specialTag) {
            dealLabelFields.push(specialTag);
        }
        const season = getField(option as ATRecord<Partial<Deals>>, "Season");
        dealLabelFields.push(season);
        return `${density} Acre ${dealLabelFields.join(" - ")}`;
    };


    const onSubmit: SubmitHandler<JobCreationData> = async data => {
        setLoading(true);
        try {
            if (data.deal === 'custom') {
                if (!window.confirm('Custom Configuration Selected. Proceed?')) {
                    return;
                }
            }
            data.deal === 'custom' ? data.deal = undefined : data.deal = data.deal;
            console.log('onSubmit', data);
            if (data.growerRecordId === CREATE_NEW_GROWER) {
                data.growerRecordId = '';
                console.log(data.growerName);
            }
            if (data.growerName.startsWith('New Grower: ')) {
                data.growerName = data.growerName.replace(/^New Grower: /, '');
            }


            if (data.growerRecordId !== undefined && data.growerRecordId !== '' && data.growerCell !== '') {
                await updateRecord("Growers", "Sampling Notification Phone Number", data.growerCell, data.growerRecordId);
            }
            await toast.loading('Job submitting...');
            console.log("submited data", data);
            await submitJobs([data]);
            await toast.dismiss();
            setLoading(false);
            await toast.success('Job submitted successfully!');

            // TODO: need to reset form
            reset({
                ...props,
                growerRecordId: CREATE_NEW_GROWER,
                fieldPriority: 'Regular Turn (default)',
                submitterEmail: userContext.email,
                submitterName: userContext.full_name,
                growerName: '',
                growerCell: '',
                fieldName: '',
                boundary: undefined,
                boundaryGeoJSON: undefined,
                recordBoundary: false,
                collectBoundary: false,
            });
            setGrowerSelected(undefined);
            setDeal(props.selectedDeal);
            setJobType('');
        } catch (error) {
            console.error(error);
            setLoading(false);
            await toast.dismiss();
            await toast.error('Error submitting job');
        } finally {
            setLoading(false);
        }
    }

    const onError: SubmitErrorHandler<JobCreationData> = (errors) => {
        const data = watch("growerName");
        console.log(data);
        toast.error('Please fill in all required fields correctly.');
        for (const errorKey of Object.keys(errors)) {
            // @t-ignore
            toast.error(errors[errorKey]?.message);
        }
    };

    function FileField(
        title: string,
        helpText: string,
        key: keyof JobCreationData,
        data: JobCreationData,
        required?: boolean,
        hidden?: boolean,

    ) {
        if (hidden) return;
        const handleRemoveFile = (index: number) => {
            const updatedFiles = Array.from(data[key] as File[]);
            updatedFiles.splice(index, 1);
            setValue(key, updatedFiles.length ? updatedFiles : undefined);
        };

        const isRequired = () => {
            if (key === 'boundary') {
                return jobType === 'grid' || jobType === 'modgrid';
            }
            if (key === 'zones') {
                return jobType === 'zones';
            }
            return required;
        };

        return (
            <>
                <FormField
                    title={title}
                    required={required}
                    helpText={helpText}
                    // @ts-ignore
                    error={errors[key]?.message}
                    input={
                        <Box
                            sx={{
                                '& > :not(style)': { m: 1 },
                            }}
                        >
                            <input
                                accept="*/*"
                                style={{ display: 'none' }}
                                id={`contained-button-file-${key}`}
                                multiple
                                type="file"
                                {...register(key, {
                                    required: required ? "File upload required for Job/Configuration type" : undefined,
                                })}
                            />
                            <label htmlFor={`contained-button-file-${key}`}>
                                <Button variant="contained" component="span">
                                    Upload
                                </Button>
                            </label>
                            {data[key] && (
                                <div>
                                    {Array.from(data[key] as File[]).map((file, index) => (
                                        <Box key={index} display="flex" alignItems="center">
                                            <Typography>{file.name}</Typography>
                                            <Button
                                                onClick={() => handleRemoveFile(index)}
                                                variant="outlined"
                                                color="error"
                                                size="small"
                                                sx={{ ml: 1 }}
                                            >
                                                Remove
                                            </Button>
                                        </Box>
                                    ))}
                                </div>
                            )}
                        </Box>
                    }
                />
            </>
        );
    }

    // const growerRecordId = watch("growerRecordId");
    // if (!growerRecordId || growerRecordId === CREATE_NEW_GROWER) {
    //     if (growerIDSelected) setGrowerIDSelected('');
    // } else {
    //     // TODO need better validation...
    //     // setGrowerIDSelected(props.growers.find(grower => grower.id === growerRecordId)?.id ?? '');
    //     // const growerName = props.growerOptions.find(option => option.id === growerRecordId)?.label ?? '';
    //     // if (growerName && growerName !== growerSelected) {
    //     //     setGrowerSelected(growerName);
    //     // }
    // }

    const readyToSampleNow = watch("readyToSampleNow");

    const syncMethod = getField(props.selectedDeal, "Sync Method");
    const secondSyncMethod = getField(props.selectedDeal, "2nd Sync Method");
    const labIDRequired = syncMethod === 'Lab ID' || syncMethod === 'Lab ID Immut' || secondSyncMethod === 'Lab ID';

    const filesNotNeeded = props.company ? getField(props.company, "Portal Configuration Options")?.includes("Submission: No Files Needed") : false;
    const filesNeeded = !filesNotNeeded;

    const syncIDIsEventID = syncMethod === 'Event ID' || syncMethod === 'Event ID Immut';
    const eventIDRequired = filesNeeded && syncIDIsEventID;

    return (
        <>
            {loading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1300,
                    }}
                >
                    <LoadingComponent />
                    <Typography variant="h5">Submitting Job...</Typography>
                </Box>
            )}
            <Grid
                alignItems="stretch"
                alignContent="stretch"
                container
                maxWidth="md"
                margin="auto"
                component="form"
                spacing={1}
                onSubmit={handleSubmit(onSubmit, onError)}
            >
                <Grid item xs={11}>
                    <Accordion defaultExpanded={false}>
                        <AccordianSummary expandIcon={<ExpandMoreIcon />}>
                            <Tooltip title="Change your Sampling configuration here" arrow>
                                <Typography variant='h6'>Sampling Configuration Edits</Typography>
                            </Tooltip>
                        </AccordianSummary>
                        <AccordionDetails>
                            <Grid
                                alignItems="stretch"
                                alignContent="stretch"
                                container
                                maxWidth="md"
                                margin="auto"
                                component="form"
                                spacing={1}
                            >
                                <FormField
                                    title="Update Deal:"
                                    helpText="Select a deal"
                                    required
                                    error={errors.deal?.message}
                                    input={
                                        <Autocomplete
                                            fullWidth={true}
                                            size="small"
                                            options={[...props.allDeals, { id: 'custom', label: 'Create New Configuration' }]}
                                            value={props.allDeals.find((deal) => deal.id === watch("deal")) || { id: 'custom', label: 'Create New Configuration' }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params} />
                                            )}

                                            // onBlur allows searching for the deal by name
                                            onBlur={(event) => {
                                                const inputValue = (event.target as HTMLInputElement).value;
                                                const selectedDeal = (props.allDeals.find(deal => getDealLabel(deal) === inputValue) || { id: 'custom', label: 'Create New Configuration' }) as ATRecord<Deals>;

                                                if (selectedDeal.id === 'custom') {
                                                    setDeal({ id: 'custom', label: 'Create New Configuration' });
                                                    setValue("deal", 'custom');
                                                } else {
                                                    setDeal(selectedDeal);
                                                    setValue("deal", selectedDeal.id);
                                                    setValue("frequencyOfMicros", getField(selectedDeal, 'Freq of Add-Ons #Every Nth Sample  #bill'));
                                                    setValue("gridSize", getField(selectedDeal, 'Density/Grid #serviceinfo'));
                                                    setValue("depth", getField(selectedDeal, 'Depth (in.) #serviceinfo') || 0);
                                                    const labPackage = getField(selectedDeal, 'Test Pckg Display');
                                                    setValue("labPackage", Array.isArray(labPackage) ? labPackage[0] : labPackage || 'No test package specified');
                                                }
                                            }}
                                            getOptionLabel={getDealLabel}
                                        />
                                    }

                                />
                                <FormField
                                    title="Frequency of Micros"
                                    helpText="Frequency of Micros in the configuration"
                                    required
                                    error={errors.frequencyOfMicros?.message}
                                    input={
                                        <TextField
                                            size="medium"
                                            variant="outlined"
                                            value={watch("frequencyOfMicros")}
                                            fullWidth {...register("frequencyOfMicros", {
                                                required: "Frequency of Micros is required",
                                                pattern: {
                                                    value: /^[1-9][0-9]*$/,
                                                    message: 'Please enter a valid integer'
                                                }
                                            })
                                            }
                                            {...updateDeal}
                                        />}
                                />
                                <FormField
                                    title={'Depth (in.)'}
                                    helpText="Enter the depth in inches"
                                    required
                                    error={errors.depth?.message}

                                    input={
                                        <TextField variant="outlined" fullWidth color={errors.depth ? "error" : "primary"} {
                                            ...register("depth", {
                                                required: "Enter a valid depth in inches",
                                                pattern: {
                                                    value: /-?\d+(\.\d+)?/,
                                                    message: 'Please enter a valid number in inches'
                                                }
                                            })
                                        }
                                            {...updateDeal}
                                        />}
                                />

                                <FormField
                                    title={'Density'}
                                    required
                                    error={errors.gridSize?.message}
                                    helpText="Enter the density of the configuration"
                                    input={
                                        <TextField variant="outlined" fullWidth color={errors.gridSize ? "error" : "primary"} {
                                            ...register("gridSize", {
                                                required: "Enter a valid density",
                                                pattern: {
                                                    value: /-?\d+(\.\d+)?/,
                                                    message: 'Please enter a valid number in density'
                                                }
                                            })
                                        }
                                            {...updateDeal}
                                        />}
                                />

                                <FormField
                                    title={'Lab Package'}
                                    error={errors.labPackage?.message}
                                    required
                                    helpText="Enter the lab package for the configuration"
                                    input={
                                        <TextField variant="outlined" fullWidth color={errors.labPackage ? "error" : "primary"} {
                                            ...register("labPackage", {
                                                required: "Enter a valid lab package",
                                            })
                                        }
                                            {...updateDeal}
                                        />}
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {/* spacing */}
                <Grid item xs={12}>
                    <Box mt={4} />
                </Grid>


                <Grid item xs={11}>
                    <Accordion defaultExpanded={true}>
                        <AccordianSummary expandIcon={<ExpandMoreIcon />}>
                            <PortalTooltip title="Grower Details">
                                <Typography variant='h6'>Grower Details</Typography>
                            </PortalTooltip>
                        </AccordianSummary>
                        <AccordionDetails >
                            <Grid
                                alignItems="stretch"
                                alignContent="stretch"
                                container
                                maxWidth="md"
                                margin="auto"
                                spacing={1}
                            >
                                <FormField
                                    title={"Grower Name"}
                                    required
                                    error={errors.growerName?.message}
                                    input={
                                        <Controller
                                            name="growerName"
                                            control={control}
                                            rules={{ required: "Grower Name is Required" }}
                                            render={({ field: { onChange, value } }) => (
                                                <Autocomplete
                                                    fullWidth={true}
                                                    value={growerSelected}
                                                    options={[...props.growers]}
                                                    freeSolo
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Grower Name"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                    filterOptions={(options, params) => {
                                                        const filtered = filter(options, params);

                                                        if (params.inputValue !== '' && !props.growers.some(grower => getField(grower, 'Grower Name').toLowerCase() === params.inputValue.toLowerCase())) {
                                                            filtered.push({
                                                                id: CREATE_NEW_GROWER,
                                                                "Grower Name": `New Grower: ${params.inputValue}`
                                                            });
                                                        }

                                                        return filtered;
                                                    }}
                                                    getOptionLabel={(grower) => {
                                                        console.log("get option label", watch("growerRecordId"));
                                                        if (watch("growerRecordId") !== CREATE_NEW_GROWER && watch("growerRecordId") !== undefined && watch("growerRecordId") !== "") {
                                                            return getField(grower as ATRecord<Growers>, 'Grower Name') || grower as string;
                                                        }
                                                        if (typeof grower === 'string') {
                                                            return grower.startsWith("New Grower") ? grower : `New Grower: ${grower}`;
                                                        }

                                                        return getField(grower as ATRecord<Growers>, 'Grower Name');
                                                    }}
                                                    onChange={(event, value) => {
                                                        console.log(event.type, "event.type");
                                                        console.log(typeof event.type, "typeof event.type");

                                                        if (event.type == 'blur' || event.type == 'keydown') {
                                                            console.log("value", value);
                                                            // need to check for {id: and "grower name"}
                                                            console.log("typeof value", typeof value);
                                                            if (typeof value === 'string') {
                                                                const newValue = (value as string).startsWith("New Grower:") ? value.replace("New Grower: ", "") : value;
                                                                if (newValue !== '' && !props.growers.some(grower => getField(grower, 'Grower Name').toLowerCase() === newValue.toLowerCase())) {
                                                                    console.log("update since blur string");
                                                                    console.log("value", value);
                                                                    setValue("growerName", newValue);
                                                                    setValue("growerRecordId", CREATE_NEW_GROWER);
                                                                    setGrowerSelected({ id: CREATE_NEW_GROWER, "Grower Name": value });
                                                                    trigger("growerName");
                                                                    return;
                                                                }
                                                                const grower = props.growers.find(grower => getField(grower, 'Grower Name').toLowerCase() === newValue.toLowerCase());
                                                                if (grower) {
                                                                    console.log("change grower object on blur");
                                                                    setValue("growerName", getField(grower as ATRecord<Growers>, "Grower Name") as string);
                                                                    setValue("growerRecordId", grower?.id);
                                                                    setGrowerSelected(grower as ATRecord<Growers>);
                                                                }
                                                            } else if (value && value.id === CREATE_NEW_GROWER) {
                                                                console.log("change new grower on blur");
                                                                setValue("growerName", getField(value as ATRecord<Partial<{ id: string, "Grower Name": string }>>, "Grower Name"));
                                                                setValue("growerRecordId", CREATE_NEW_GROWER);
                                                                setGrowerSelected(value);
                                                                trigger("growerName");
                                                            } else if (typeof value === 'object') {
                                                                console.log("change object on blur");
                                                                setValue("growerName", getField(value as ATRecord<Growers>, "Grower Name") as string);
                                                                setValue("growerRecordId", value?.id);
                                                                setGrowerSelected(value as ATRecord<Growers>);
                                                            }
                                                            return;
                                                        }
                                                        console.log(event, "event");
                                                        console.log(value, "value");
                                                        if (typeof value === 'string') {
                                                            console.log("change string");
                                                            setValue("growerName", value);
                                                            setValue("growerRecordId", CREATE_NEW_GROWER);
                                                            setGrowerSelected({ id: CREATE_NEW_GROWER, "Grower Name": value });
                                                        } else if (value && value.id === CREATE_NEW_GROWER) {
                                                            console.log("change new grower");
                                                            setValue("growerName", getField(value as ATRecord<Partial<{ id: string, "Grower Name": string }>>, "Grower Name"));
                                                            setValue("growerRecordId", CREATE_NEW_GROWER);
                                                            setGrowerSelected(value);
                                                        } else {
                                                            console.log("change object");
                                                            setValue("growerName", getField(value as ATRecord<Growers>, "Grower Name") as string);
                                                            setValue("growerRecordId", value?.id);
                                                            setGrowerSelected(value as ATRecord<Growers>);
                                                        }
                                                        trigger("growerName");
                                                    }}
                                                    autoSelect={true}
                                                    selectOnFocus
                                                    clearOnBlur
                                                    handleHomeEndKeys
                                                />
                                            )}
                                        />
                                    }
                                />

                                <FormField
                                    title={'Grower Cell Number'}
                                    required={watch("sendGrowerSamplingNotification")}
                                    error={errors.growerCell?.message}
                                    input={
                                        <TextField variant="outlined"
                                            //value={watch("growerName")}

                                            color={errors.growerCell ? "error" : "primary"}
                                            fullWidth
                                            {...register("growerCell", {
                                                required: !watch("sendGrowerSamplingNotification") ? undefined : 'Please provide a cell phone number to enable grower text notifications',
                                                pattern: {
                                                    value: /^\+?(?=.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d)(?=.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?).*$/,
                                                    message: 'Please enter a valid 10 or 11 digit cell phone number'
                                                }
                                            })
                                            }
                                        />}
                                />

                                <FormField
                                    title="Send grower sampling notification texts?"
                                    input={
                                        <Checkbox {
                                            ...register("sendGrowerSamplingNotification")
                                        } />
                                    }
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>


                <Grid item xs={11}>
                    <Accordion defaultExpanded={true}>
                        <AccordianSummary expandIcon={<ExpandMoreIcon />}>
                            <PortalTooltip title="Job Data">
                                <Typography variant='h6'>Job Data</Typography>
                            </PortalTooltip>
                        </AccordianSummary>
                        <AccordionDetails >
                            <Grid
                                alignItems="stretch"
                                alignContent="stretch"
                                container
                                maxWidth="md"
                                margin="auto"
                                spacing={1}
                            >


                                <FormField
                                    title="Field Name"
                                    error={errors.fieldName?.message}
                                    required
                                    helpText="Enter the name of the field"
                                    input={
                                        <TextField
                                            variant="outlined"
                                            color={errors.fieldName ? "error" : "primary"}
                                            fullWidth {
                                            ...register("fieldName", {
                                                required: "Field name is required"
                                            })
                                            }
                                        />
                                    }
                                />

                                <FormField
                                    title="Farm Name"
                                    helpText="Only required if field name is not unique"
                                    input={
                                        <TextField
                                            variant="outlined"
                                            fullWidth {...register("farmName")}
                                        />}
                                />


                                <FormField
                                    title="Event/Sync Code"
                                    helpText="This is used to sync your data with the appropriate software"
                                    input={
                                        <TextField
                                            variant="outlined"
                                            color={errors.syncCode ? "error" : "primary"}
                                            fullWidth
                                            {
                                            ...register("syncCode")
                                            }
                                        />}
                                    required={eventIDRequired}
                                    hidden={!eventIDRequired}
                                />


                                <FormField
                                    title="Ready to Sample Now"
                                    helpText="Select if field is ready to sample now"
                                    input={
                                        <Checkbox {
                                            ...register("readyToSampleNow")
                                        }
                                        />
                                    }
                                />

                                {readyToSampleNow && <FormField
                                    title="Field Priority"
                                    helpText="Select the priority of the field"
                                    input={
                                        <Select
                                            fullWidth
                                            value={watch("fieldPriority")}
                                            {
                                            ...register("fieldPriority", {
                                                required: "Configuration is required",
                                            })
                                            }>
                                            {(JobsPriorityOvrd).map((priority, index) => {
                                                return (<MenuItem key={index} value={priority}>{priority}</MenuItem>);
                                            })}
                                        </Select>}
                                />}

                                {FileField("Field Boundary", "This is for your field boundary shp file", "boundary", watch(), !filesNotNeeded, filesNotNeeded)}

                                {FileField("Zone Regions", "This is for your Zone shp file", "zones", watch(), !filesNotNeeded && jobType == "Zone", filesNotNeeded || !(jobType == "Zone"))}

                                <FormField
                                    title="Attach Points?"
                                    helpText="Select if you want to attach points (points should be attached for Zones and ModGrid), then upload points file"
                                    input={
                                        <Checkbox {
                                            ...register("pointsAttached")
                                        }
                                        />}
                                    hidden={filesNotNeeded}
                                />

                                {FileField("Points", "This is for your point shp file", "points", watch(), watch("pointsAttached"), filesNotNeeded || !watch("pointsAttached"))}

                                <FormField
                                    title="Record HD Boundary?"
                                    helpText="Select this is you want us to collect a boundary on your field"
                                    input={
                                        <Checkbox {
                                            ...register("collectBoundary")
                                        }
                                        />}
                                />

                                <FormField
                                    title="Lab Presubmission Code"
                                    helpText="This is the code for the lab submission"
                                    input={
                                        <TextField
                                            variant="outlined"
                                            color={errors.syncCode ? "error" : "primary"}
                                            fullWidth
                                            {
                                            ...register("syncCode")
                                            }
                                        />}
                                    required={labIDRequired}
                                    hidden={!labIDRequired}
                                />



                                <FormField
                                    title="Special Lab Instructions"
                                    helpText="Enter any special notes for lab"
                                    input={
                                        <TextField
                                            variant="outlined"
                                            multiline
                                            rows={3}
                                            fullWidth {
                                            ...register("specialLabInstructions")
                                            }
                                        />}
                                    hidden={props.company && getField(props.company, "Portal Configuration Options")?.includes("Submission: Show Special Lab Instructions") || true}
                                />

                                <FormField
                                    title="Submission Notes"
                                    helpText="Enter any notes for the submission(restrictions, special notes)"
                                    input={
                                        <TextField
                                            variant="outlined"
                                            multiline
                                            rows={3}
                                            fullWidth {
                                            ...register("submissionNotes")
                                            }
                                        />}
                                />

                                {FileField("Field Notes", "This is for any additional field files, like pullin instructions", "fieldNotes", watch(), false, false)}

                                {/* TODO we have a better superuser construct but that may be only in the cloud
                                It's also not a big deal of someone finds this code and makes a test job */}
                                {userContext.email.includes('@rogoag.com') &&
                                    <FormField
                                        title="Testing?"
                                        helpText="Enable if this is a testing job"
                                        input={
                                            <Checkbox {
                                                ...register("testing")
                                            }
                                            />}
                                    />
                                }

                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <div style={{ padding: 5, paddingLeft: 8 }}>
                    <Typography> Fields marked with <a style={{ color: 'red' }}>*</a> are required</Typography>
                </div>



                <Grid item xs={12}>
                    <Button fullWidth type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </>)
}